import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LinkBar from "../components/linkBar"
import Modal from "../components/modal"
import Footer from "../components/footer"
import BackToTop from "../components/backToTop"
import ButtonsBox from "../components/buttonsBox"
import Helmet from "react-helmet"

import { useSiteMetadataSizes } from "../hooks/useSiteMetadata"

import AfMainBg from "../images/af-body-bg.jpg"
import AfTopBg from "../images/af-top-bg.gif"
import AfLogoPc from "../images/af-logo-pc.png"
import AfLogoMobile from "../images/af-logo-mobile.png"
import AfDogCard from "../images/af-card.png"

const Header = styled.div`
  background-image: url(${AfTopBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top 30%;
  position: relative;
  width: 100%;
  height: 738px;
  font-family: Cormorant;
  font-size: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 840px) {
    font-size: 80px;
    height: 496px;
  }
`

const HeaderContainer = styled.div`
  width: 100%;
  height: 738px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  @media (max-width: 840px) {
    font-size: 80px;
    height: 496px;
  }
`

const VooxText = styled.div`
  margin-bottom: -55px;

  @media (max-width: 840px) {
    margin-bottom: -40px;
  }
`

const DogsText = styled.div`
  margin-top: -90px;

  @media (max-width: 840px) {
    margin-top: -55px;
  }
`

const SubHeaderPc = styled.div`
  font-size: 31px;
  font-family: "Noto Sans JP";
  font-weight: bold;

  margin: 0 16px;

  @media (max-width: 840px) {
    display: none;
  }
`

const SubHeaderMobile = styled.div`
  font-size: 31px;
  font-family: "Noto Sans JP";
  font-weight: bold;

  margin: 0 16px;

  display: none;

  @media (max-width: 840px) {
    display: initial;
    font-size: 22px;
    margin-top: 12px;
    text-align: center;
    line-height: 40px;
  }
`

const For = styled.main`
  width: 447px;
  height: 233px;
  background-image: url(${AfLogoPc});
  background-size: cover;
  z-index: 100;
  margin-bottom: 30px;

  @media (max-width: 840px) {
    width: 295px;
    height: 148px;
    background-image: url(${AfLogoMobile});
  }
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  background-repeat: repeat;
  background: linear-gradient(
    #ffed46,
    #aeff9a,
    #6af6ff,
    #5a6bff,
    #f257ff,
    #ff4949,
    #f3a26b,
    #f3a26b
  );

  @media (max-width: 840px) {
    padding: 0 16px;
  }

  /* background-image: url(${AfMainBg}); */
`

const GifContainer = styled.div`
  position: absolute;
  top: ${({ top }) => `${top}px`};
  height: ${({ height }) => `${height}px`};
  z-index: 100;
  width: ${({ width }) => `${width}px`};
  display: flex;
  justify-content: space-between;

  @media (max-width: 840px) {
    display: none;
  }
`

const GifImg = styled.img`
  object-fit: contain;
`

const DescriptionPc = styled.div`
  text-align: center;
  font-weight: bold;
  line-height: ${({ dense }) => (dense ? "40px" : "60px")};
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  position: relative;
  font-size: 20px;
  width: 100%;

  @media (max-width: 840px) {
    display: none;
  }
`

const DescriptionMobile = styled.div`
  text-align: center;
  font-weight: bold;
  line-height: ${({ dense }) => (dense ? "40px" : "60px")};
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  position: relative;
  font-size: 20px;
  width: 100%;
  display: none;

  @media (max-width: 840px) {
    display: initial;
  }
`

const Caption = styled.div`
  margin-top: 100px;
  margin-bottom: 64px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
`

const Title = styled.h1`
  border-bottom: 4px solid #000000;
  margin-top: 48px;
  padding: 0px 16px 16px;
`

const DogCard = styled.div`
  height: 548px;
  width: 350px;
  margin-top: 60px;
  background-image: url(${AfDogCard});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

const Fukidashi = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 100px;
  padding: 12px 30px;
  padding-bottom: 16px;
  min-width: 120px;
  max-width: 100%;
  color: black;
  font-size: 20px;
  font-weight: bold;
  background: #ffed47;
  box-sizing: border-box;

  &:before {
    content: "";
    position: absolute;
    bottom: -40px;
    left: 50%;
    margin-left: -14px;
    border: 14px solid transparent;
    border-top: 36px solid #ffed47;
    z-index: 2;
  }
`

const BackToTopWrapper = styled.div`
  position: absolute;
  bottom: -50px;
  right: 32px;

  @media (max-width: 840px) {
    position: initial;
  }
`

const Line2 = styled.div`
  margin-top: 64px;
  margin-bottom: 38px;
  border-bottom: 1px solid #000000;
  width: 981px;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptop}px) {
    width: 700px;
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px) {
    width: 520px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {
    width: 460px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobile}px) {
    width: 240px;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) {
  }
`

const IndexPage = ({ data }) => {
  const sizes = useSiteMetadataSizes()

  const title = "VOOX for Dogs"
  const description = "あなたの愛犬も、10分のスキマ時間を学びの時間に！"

  return (
    <Layout>
      <Helmet
        title={title}
        meta={[
          { name: "description", content: description },
          { name: "og:title", content: title },
          { name: "og:description", content: description },
          { name: "og:image", content: "https://voox.me/images/af-ogp.jpg" },
          { name: "og:url", content: "https://voox.me/dogs/" },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:site", content: "@VOOX_jp" },
        ]}
      />
      <LinkBar />
      <Header>
        <HeaderContainer style={{ zIndex: 100 }}>
          {/* <VooxText>VOOX</VooxText> */}
          <For />
          {/* <DogsText>DOGS</DogsText> */}
          <SubHeaderPc>
            あなたの<span style={{ color: "yellow" }}>愛犬</span>
            も、10分のスキマ時間を学びの時間に！
          </SubHeaderPc>
          <SubHeaderMobile>
            あなたの<span style={{ color: "yellow" }}>愛犬</span>
            も、10分の
            <br />
            スキマ時間を学びの時間に！
          </SubHeaderMobile>
        </HeaderContainer>
      </Header>
      <Main>
        <DescriptionPc marginTop={110}>
          新しい音声メディア「VOOX」は、4月1日、
          <br />
          新サービスとして「VOOX for DOGS」をリリースしました。
          <br />
          <br />
          学びたくても時間が取れないのは我々だけでなく、
          <br />
          ワンちゃんも同じです。
          <br />
          そんなワンちゃんがスキマ時間を使って
          <br />
          学びの時間にできる新サービスを開始します。
          <br />
          <br />
          散歩の時間も学びの時間に。
          <br />
          餌を待つ時間も学びの時間に。
          <br />
          VOOXは、人間の皆さんだけでなく、
          <br />
          ワンちゃんたちにも楽しい学びを提供します。
        </DescriptionPc>
        <DescriptionMobile marginTop={110}>
          新しい音声メディア「VOOX」は、
          <br />
          4月1日、
          <br />
          新サービスとして「VOOX for
          <br />
          DOGS」をリリースしました。
          <br />
          <br />
          学びたくても時間が取れないのは
          <br />
          我々だけでなく、
          <br />
          ワンちゃんも同じです。
          <br />
          そんなワンちゃんがスキマ時間を
          <br />
          使って学びの時間にできる
          <br />
          新サービスを開始します。
          <br />
          <br />
          散歩の時間も学びの時間に。
          <br />
          餌を待つ時間も学びの時間に。
          <br />
          VOOXは、
          <br />
          人間の皆さんだけでなく、
          <br />
          ワンちゃんたちにも楽しい学びを
          <br />
          提供します。
        </DescriptionMobile>
        <Line2 sizes={sizes} />
        <Title>コンテンツ第一弾</Title>
        <DogCard />
        <DescriptionPc marginTop={55} dense={true}>
          パピヨンで、飼い主に甘えるのが日本一上手なMochiちゃんが、
          <br />
          「飼い主を惚れさせる」効果的な鳴き方、吠え方を伝授します。
          <br />
          この会話術をマスターすると、
          <br />
          飼い主をメロメロにさせることができます。
        </DescriptionPc>
        <DescriptionMobile marginTop={55} dense={true}>
          パピヨンで、飼い主に甘えるのが
          <br />
          日本一上手なMochiちゃんが、
          <br />
          「飼い主を惚れさせる」効果的な
          <br />
          鳴き方、吠え方を伝授します。
          <br />
          この会話術をマスターすると、
          <br />
          飼い主をメロメロに
          <br />
          させることができます。
        </DescriptionMobile>
        <Fukidashi>▼ダウンロードはこちらから</Fukidashi>
        <div style={{ marginTop: 12 }}>
          <ButtonsBox />
        </div>
        <Caption>
          ＊「VOOX for
          DOGS」はエイプリルフール企画のため、4月1日限定となります。
          <br />
          引き続き、VOOXの愛用のほどよろしくお願いいたします。
        </Caption>
        <BackToTopWrapper>
          <BackToTop sizes={sizes} />
        </BackToTopWrapper>
        <GifContainer top={400} height={170} width={780}>
          <GifImg src={"/images/af-gif-1.gif"} />
          <GifImg src={"/images/af-gif-1.gif"} />
        </GifContainer>
        <GifContainer top={580} height={125} width={880}>
          <GifImg src={"/images/af-gif-2.gif"} />
          <GifImg src={"/images/af-gif-2.gif"} />
        </GifContainer>
        <GifContainer top={1300} height={140} width={820}>
          <GifImg src={"/images/af-gif-3.gif"} />
          <GifImg src={"/images/af-gif-3.gif"} />
        </GifContainer>
      </Main>
      <Footer sizes={sizes} />
    </Layout>
  )
}

export default IndexPage
